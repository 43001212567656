import React from 'react'
const incentives = [
    {
        name: 'Initial Consultation',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
        description: "Our team will meet with you to discuss your renovation project, including your goals, budget, and timeline.",
    },
    {
        name: 'Design and Planning',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
        description: "Our team will work with you to create a custom design that meets your unique needs and tastes.",
    },
    {
        name: 'Construction',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Our team of experts will handle all aspects of the construction process, including demolition, installation, and finishing.",
    },
    {
        name: 'Final Walkthrough',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description:
            "Once complete, we will conduct a final walkthrough with you to ensure that you are completely satisfied with the finished product.",
    },
]
export const RenovationProcess = () => {
    return (
        <div className="bg-brand-800 py-4">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className=" px-6 py-16 sm:p-16">
                    <div className="mx-auto max-w-xl lg:max-w-none">
                        <div className="text-center">
                            <h4 className="text-2xl font-bold tracking-tight text-white">
                                Our Renovation Process
                            </h4>
                        </div>
                        <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-4">
                            {incentives.map((incentive, i) => (
                                <div key={incentive.name} className="text-center sm:flex sm:text-left lg:block lg:text-center">
                                    {/* <div className="sm:flex-shrink-0">
                                        <div className="flow-root">
                                            <img className="mx-auto h-16 w-16" src={incentive.imageSrc} alt="" />
                                        </div>
                                    </div> */}
                                    <div className="sm:flex-shrink-0">
                                        <div className="flow-root text-4xl font-display text-white">
                                            {i + 1}
                                        </div>
                                    </div>
                                    <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                                        <h3 className="text-sm font-medium text-gray-100">{incentive.name}</h3>
                                        <p className="mt-2 text-sm text-gray-400">{incentive.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
