import React, { useRef } from 'react'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OPEN_CONTACT_MODAL } from '../consts'
import Popup from 'reactjs-popup'
import { ArrowRight, X } from 'react-feather'
import Button from './atoms/Button'
import ContactForm from '../components/estimate-form'


const FeaturedColumns = (data) => {
  const dataColumnsCount = data.data.map((item, index) => (
    <FeaturedColumn key={index} item={item} columnsCount={data.data.length} />
  ))
  return dataColumnsCount
}


const FeaturedColumn = (data) => {
  const learnMoreRef = useRef(null)
  const buttonProps = {}


  return (
    <div key={data.item.slug} className="flex flex-col bg-white border rounded-xl ">
      <GatsbyImage
        className="rounded-t-xl"
        image={getImage(data.item.heroImage)}
        style={{ width: '100%', height: '200px', objectFit: 'cover' }}
        alt={data.item.serviceTitle + ' Contractor'}
      />
      <div className="flex-1 relative pt-8 px-6 pb-6 md:px-8" >

        <h3 className="text-xl font-bold text-gray-900">{data.item.featuredLabel
          ? data.item.featuredLabel
          : data.item.serviceTitle}</h3>
        <p className="mt-4 text-base text-gray-500">{data.item && data.item.shortExcerpt}</p>
      </div >
      <div className=" pb-6  rounded-br-xl  px-6 md:px-8">

        <Button
          class="bg-white text-gray-900 hover:bg-gray-100  "
          to={data.item.slug}
          title={'Learn more about ' + data.item.serviceTitle}
        >
          Learn more <ArrowRight size={18} className="ml-2" />
        </Button>

      </div>
    </div >

  )
}

const FeaturedItems = ({ data }) => {
  return (
    <div className='bg-gradient-to-b from-gray-100 to-white'>
      <section
        className=" max-w-7xl mx-auto relative py-16 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h4 className="sr-only" id="contact-heading">
          Featured Services
        </h4>
        <h3 className='font-display font-extrabold text-3xl text-brand-700  md:text-3xl '>Quality Seattle Home Renovations</h3>
        <p className=' mb-6 pt-4 text-base md:text-lg text-brand-400  md:leading-7  '>Seattle Renovation Group offers innovative kitchen & bathroom remodeling, ADU construction, and deck building services to residential, commercial, & industrial sectors in Seattle & surrounding areas. </p>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2  lg:gap-6">
          <FeaturedColumns data={data} />

        </div>
      </section></div>

  )
}
export default FeaturedItems
